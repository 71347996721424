// src/pages/Home.js
import React from 'react';
import './Home.css'; // If you have custom styles

const Home = () => {
  return (
    <div className="container text-center mt-5">
      <h1>Welcome to FitGluco</h1>
      <p className="lead">Achieve Your Fitness Goals, Live Healthier</p>
    </div>
  );
};

export default Home;
