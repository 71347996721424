// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './pages/Home';
import Profile from './pages/Profile';
import WorkoutPlans from './pages/WorkoutPlans';
import NutritionTips from './pages/NutritionTips';
import ActivityTracker from './pages/ActivityTracker';
import Education from './pages/Education';
import Community from './pages/Community';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Settings from './pages/Settings';
import { AuthProvider } from './contexts/AuthContext';
import './assets/styles/main.css';

function App() {
  return (
    <AuthProvider>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/workout-plans" element={<WorkoutPlans />} />
        <Route path="/nutrition-tips" element={<NutritionTips />} />
        <Route path="/activity-tracker" element={<ActivityTracker />} />
        <Route path="/education" element={<Education />} />
        <Route path="/community" element={<Community />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
      <Footer />
    </AuthProvider>
  );
}

export default App;
