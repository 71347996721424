// src/pages/ActivityTracker.js
import React from 'react';
import './ActivityTracker.css';

const ActivityTracker = () => {
  return (
    <div>
      <h1>Activity Tracker</h1>
      <p>Log your workouts and monitor your progress.</p>
    </div>
  );
};

export default ActivityTracker;
