// src/pages/Community.js
import React from 'react';
import './Community.css'

const Community = () => {
  return (
    <div>
      <h1>Community</h1>
      <p>Share your fitness progress and support each other.</p>
    </div>
  );
};

export default Community;
