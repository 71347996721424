// src/pages/Education.js
import React from 'react';
import './Education.css'

const Education = () => {
  return (
    <div>
      <h1>Education</h1>
      <p>Access articles, videos, and tips on general fitness, healthy eating, and wellness.</p>
    </div>
  );
};

export default Education;
