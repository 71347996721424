// src/pages/WorkoutPlans.js
import React from 'react';
import './WorkoutPlans.css'

const WorkoutPlans = () => {
  return (
    <div>
      <h1>Workout Plans</h1>
      <p>Personalized workout routines based on your preferences and fitness level.</p>
    </div>
  );
};

export default WorkoutPlans;
