// src/pages/NutritionTips.js
import React from 'react';
import './NutritionTips.css'

const NutritionTips = () => {
  return (
    <div>
      <h1>Nutrition Tips</h1>
      <p>General dietary tips and meal planning suggestions.</p>
    </div>
  );
};

export default NutritionTips;
