// src/pages/Settings.js
import React from 'react';
import './Settings.css'

const Settings = () => {
  return (
    <div>
      <h1>Settings</h1>
      <p>Customize your app preferences.</p>
    </div>
  );
};

export default Settings;
