// src/pages/Profile.js
import React, { useEffect, useState } from 'react';
import './Profile.css';
import { fetchData } from '../services/api';

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProfileData = async () => {
      try {
        const data = await fetchData('/profile');
        setProfileData(data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    getProfileData();

    // Set a timeout to stop the spinner after 5 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    // Cleanup the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="container text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (!profileData) {
    return (
      <div className="container text-center mt-5">
        <p>Failed to load profile data.</p>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Profile</h1>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Name: {profileData.name}</h5>
          <p className="card-text">Email: {profileData.email}</p>
          <p className="card-text">Joined: {profileData.joined}</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
